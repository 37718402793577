<template>
  <div v-if="pendiente">
    <b-modal id="modal-enfermeria" size="lg"
      title="Enfermeria"
      :header-bg-variant="pendiente.consulta.tipo_atencion_id === 2 ? 'danger' : 'gray'"
      :visible="visible"
      @change="$emit('change', $event)"
    >
    <template #modal-header>
  <div class="container-fluid" style="font-size: 12px;">
    <div class="row">
      <div class="col-6 d-flex flex-column">
        <div class="row">
          <div class="col-auto">
            <strong>Origen:</strong>
          </div>
          <div class="col text-truncate">
            {{pendiente.consulta.origen}}
            ({{pendiente.consulta.fecha}} - Hora: {{pendiente.consulta.hora}})
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Paciente:</strong> {{pendiente.consulta.paciente}}
            - Edad: {{pendiente.consulta.edad}}
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col">
            <strong>Nº HC:</strong> {{consulta.historia_clinica}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Médico:</strong> {{pendiente.medico.medico}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<div class="row px-3">
  <div class="col-sm-4 pb-3">
    <div class="form-group">
      <label for="estatura"><strong>Estatura:</strong></label>
      <vue-numeric
        class="form-control"
        v-model="estatura"
        id="estatura"
        separator=","
        :precision="2"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label for="imc">
        <strong>IMC</strong> <span class="font-10">(Indice Masa Corporal)</span>
      </label>
      <input type="text" id="imc" class="form-control" disabled :value="imc + ' Kg/m2'">
    </div>
    <div class="form-group">
      <label for="fc">
        <strong>FC</strong> <span class="font-10">(Frecuencia Cardiaca)</span>
      </label>
      <input type="number" id="fc" class="form-control" v-model="fc" min="0" step="1">
    </div>
  </div>
  <div class="col-sm-4 pb-3">
    <div class="form-group">
      <label for="peso"><strong>Peso:</strong></label>
      <vue-numeric
        class="form-control"
        v-model="peso"
        id="peso"
        separator=","
        :precision="2"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label for="fr">
        <strong>FR</strong> <span class="font-10">(Frecuencia Respiratoria)</span>
      </label>
      <input type="number" id="fr" class="form-control" v-model="fr" min="0" step="1">
    </div>
    <div class="form-group">
      <label for="sat">
        <strong>SAT 02</strong> <span class="font-10">(Saturación de Oxigeno)</span>
      </label>
      <input type="number" id="sat" class="form-control" v-model="saturacion_oxigeno" min="0" max="100" step="1">
    </div>
  </div>
  <div class="col-sm-4 pb-3">
    <div class="form-group">
      <label for=""><strong>Temperatura:</strong> </label>
      <div class="row">
        <div class="col-sm-4 mt-2 d-flex align-items-center justify-content-end px-0">
          <label for="oral"><strong>Oral:</strong></label>
        </div>
        <div class="col-sm-8 mt-2">
          <input type="number" id="oral" class="form-control" v-model="oral" min="0" step="0.1">
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="">
        <strong>PA</strong> <span class="font-10">(Presión Arterial)</span>
      </label>
      <div class="row pb-2">
        <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
          <label for="sistolica"><strong>Sistolica:</strong></label>
        </div>
        <div class="col-sm-8">
          <input type="number" id="sistolica" class="form-control" v-model="sistolica" min="0" step="1">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
          <label for="diastolica"><strong>Diastolica:</strong></label>
        </div>
        <div class="col-sm-8">
          <input type="number" id="diastolica" class="form-control" v-model="diastolica" min="0" step="1">
        </div>
      </div>
    </div>
  </div>
</div>
      <!-- TABLERO DE INFORMACION -->
      <div v-if="pendiente.consulta.tipo_atencion_id === 2">
        <div class="row px-3">
          <form v-on:submit.prevent="addInformacion" class="col-sm-12">
            <div class="row form-group">
            <div class="col-sm-10">
              <div class="form-group">
                <label for="informacion"><strong>Tablero de Informacion:</strong></label>
                <input type="text" id="informacion" class="form-control"
                v-model="informacion"
                autocomplete="off">
              </div>
            </div>
            <div class="col-sm-2" style="margin: auto; padding-top: 10px;">
              <button class="btn btn-success" type="button" @click="addInformacion">Agregar</button>
            </div>
          </div>
          </form>
        </div>
        <div class="row" style="margin: auto;">
          <div class="col-sm-12" v-for="(informacion, index) in tablaTemporal" :key="index">
            <div class="row">
              <div class="col-sm-10">
                <span>
                  <strong> {{ informacion.user }}</strong>
                  {{ informacion.fecha }} -- Hora {{ informacion.hora }}
                </span>
                <br>
                <span> {{ informacion.descripcion }}</span>
              </div>
              <div class="col-sm-2">
                <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer;" title="Eliminar" @click="deleteInformacion(informacion)">
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN TABLERO DE INFORMACION -->
      <div class="row p-4" v-if="pendiente.consulta.tipo_atencion_id === null">
        <div class="col-sm-6 bg-gray p-3">
          <div><h4><strong>{{ pendiente.medico.medico }}</strong></h4></div>
          <div class="font-10"><strong>{{ pendiente.medico.especialidad  }}</strong></div>
          <div class="pt-3">{{medico.turno}}</div>
        </div>
        <div class="col-sm-6 bg-success p-3 text-center">
          <div>
            <h4 class="text-white"><strong>Consultorio:</strong> {{pendiente.consultorio}}</h4>
          </div>
          <div>
            <h4><strong class="text-white">{{pendiente.estado_atencion}}</strong></h4>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-secondary" @click="cancel()">
          Cancelar
        </button>
        <button class="btn bg-danger text-white" @click="guardarCerrar()">
          Guardar y cerrar
        </button>
        <button class="btn bg-custom-green" @click="guardarDatos()">
          Guardar
        </button>
        <button class="btn bg-warning"
        @click="ok()"
        :disabled = isAusente()
        v-if="pendiente.consulta.tipo_atencion_id !== 2">
          Ausente
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';
import VueNumeric from 'vue-numeric';
import { round } from 'mathjs';
import { mapState } from 'vuex';

export default {
  name: 'theModalEnfermeria',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pendiente: {
      required: true,
    },
    idEnfermeria: {
      type: Number,
    },
  },
  data() {
    return {
      informacion: null,
      tableroInformacion: [],
      peso: 0,
      estatura: 0,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      consulta: null,
      medico: null,
      signos_vitales: null,
      enfermeria: null,
      fc: null,
      fr: null,
      saturacion_oxigeno: null,
      diastolica: null,
      sistolica: null,
      temperatura: null,
      oral: null,
      tablaTemporal: [],
    };
  },
  created() {
    if (this.pendiente && this.pendiente.id) {
      this.cargarDatos();
    }
  },
  methods: {
    addInformacion() {
      const INFORMACION = {
        id: null,
        descripcion: this.informacion,
        user: this.user.name,
        user_id: this.user.id,
        fecha: this.fecha,
        hora: moment().format('HH:mm:ss'),
        estado: true,
      };
      this.tableroInformacion.push(INFORMACION);
      this.tablaTemporal.push(INFORMACION);
      this.informacion = null;
    },
    cargarDatos() {
      if (this.pendiente) {
        this.consulta = this.pendiente.consulta || null;
        this.medico = this.pendiente.medico || null;
        this.signos_vitales = this.pendiente.signos_vitales || {};
        this.tableroInformacion = this.pendiente.tablero_informacion || [];
        this.tableroInformacion = this.tableroInformacion.map((item) => ({
          ...item,
          estado: true,
        }));
        this.tablaTemporal = this.tableroInformacion.map((item) => ({ ...item }));

        if (this.signos_vitales) {
          this.fc = this.signos_vitales.frecuencia_cardiaca ?? 0;
          this.fr = this.signos_vitales.frecuencia_respiratoria ?? 0;
          this.diastolica = this.signos_vitales.presion_arterial_diastolica ?? 0;
          this.sistolica = this.signos_vitales.presion_arterial_sistolica ?? 0;
          this.temperatura = this.signos_vitales.temperatura ?? 0;
          this.peso = this.signos_vitales.peso ?? 0;
          this.estatura = this.signos_vitales.talla ?? 0;
          this.saturacion_oxigeno = this.signos_vitales.saturacion_oxigeno ?? 0;
        }
      }
    },
    deleteInformacion(item) {
      const NRO = item.id;
      const INDEX = this.tableroInformacion.findIndex((el) => el.id === NRO);
      if (INDEX !== -1) {
        this.tableroInformacion[INDEX].estado = false;
      }
      const TEMP_INDEX = this.tablaTemporal.findIndex((el) => el.id === NRO);
      if (TEMP_INDEX !== -1) {
        this.tablaTemporal.splice(TEMP_INDEX, 1);
      }
    },
    isAusente() {
      if (this.pendiente.ausente === false) {
        return false;
      }
      return true;
    },
    guardarDatos() {
      const tableroInformacionFiltrado = this.tableroInformacion.map((info) => {
        if (!info.descripcion) {
          return {
            id: null,
            descripcion: info.descripcion,
            estado: info.estado,
          };
        }
        return {
          id: info.id,
          descripcion: info.descripcion,
          estado: info.estado,
        };
      }).filter((info) => info.descripcion !== null);

      const body = {
        consulta_id: this.pendiente.consulta_id,
        signos_vitales: {
          id: this.signos_vitales.id,
          talla: this.estatura,
          peso: this.peso,
          temperatura: this.temperatura,
          indice_masa_corporal: this.imc,
          pulso: this.signos_vitales.pulso,
          frecuencia_cardiaca: this.fc,
          frecuencia_respiratoria: this.fr,
          saturacion_oxigeno: this.saturacion_oxigeno,
          presion_arterial_sistolica: this.sistolica,
          presion_arterial_diastolica: this.diastolica,
        },

        tablero_informacion: tableroInformacionFiltrado,
        estado: true,
        ausente: this.pendiente.ausente,
        cierre: false,
      };

      axios
        .put(`clinic/consultation/patient/enfermeria/update/${this.idEnfermeria}`, body)
        .then((response) => {
          util.showNotify(response.data.message, 'success');
          this.$emit('change', false);
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
      this.limpiarDatos();
    },
    guardarCerrar() {
      const tableroInformacionFiltrado = this.tableroInformacion.map((info) => {
        if (!info.descripcion) {
          return {
            id: null,
            descripcion: info.descripcion,
            estado: info.estado,
          };
        }
        return {
          id: info.id,
          descripcion: info.descripcion,
          estado: info.estado,
        };
      }).filter((info) => info.descripcion !== null);

      const body = {
        consulta_id: this.pendiente.consulta_id,
        signos_vitales: {
          id: this.signos_vitales.id,
          talla: this.estatura,
          peso: this.peso,
          temperatura: this.temperatura,
          indice_masa_corporal: this.imc,
          pulso: this.signos_vitales.pulso,
          frecuencia_cardiaca: this.fc,
          frecuencia_respiratoria: this.fr,
          saturacion_oxigeno: this.saturacion_oxigeno,
          presion_arterial_sistolica: this.sistolica,
          presion_arterial_diastolica: this.diastolica,
        },

        tablero_informacion: tableroInformacionFiltrado,
        estado: true,
        ausente: this.pendiente.ausente,
        cierre: true,
      };

      axios
        .put(`clinic/consultation/patient/enfermeria/update/${this.idEnfermeria}`, body)
        .then((response) => {
          util.showNotify(response.data.message, 'success');
          this.$emit('change', false);
          this.$emit('registroActualizado');
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
      this.limpiarDatos();
    },
    limpiarDatos() {
      this.fc = null;
      this.fr = null;
      this.diastolica = null;
      this.sistolica = null;
      this.temperatura = null;
    },
    calcularIMC() {
      if (this.peso && this.estatura && this.peso !== 0 && this.estatura !== 0) {
        this.imc = parseFloat(this.peso) / (parseFloat(this.estatura) * parseFloat(this.estatura));
        this.imc = round(this.imc, 2);
      } else {
        this.imc = null;
      }
    },
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    imc() {
      let total = 0;
      if (this.peso !== 0 && this.estatura !== 0) {
        total = parseFloat(this.peso) / parseFloat(this.estatura * this.estatura);
      }
      total = round(total, 2);
      return total;
    },
    ...mapState('main', [
      'user',
    ]),
  },
  components: {
    VueNumeric,
  },
  watch: {
    pendiente: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.cargarDatos();
        }
      },
    },
  },
};
</script>

<style scoped>
.modal-header {
  display:block !important;
}
.font-10 {
  font-size: 10px;
}
.bg-gray {
  background-color: rgb(200,200,200);
}
.color-white {
  color: white;
}
</style>
