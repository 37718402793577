<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4">
                <div class="breadcrumb mb-2 custom-top-padding">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-5">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                      :checked="estado"
                      v-model="estado">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row py-3">
              <div class="col-sm-8">
                <strong>
                  <h4>Pendientes: {{pendientes.length}}</h4>
                </strong>
                <!-- <div>Ver Cerrados</div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 p-3" v-for="(pendiente, index) in pendientes" :key="index">
                  <div class="card" @click="onClickHeaderCard(pendiente)" style="cursor: pointer;">
                    <div
                      class="card-header pb-0 border-radius-top"
                      style="cursor:pointer;"
                      :class="cambiarColorHead(pendiente.tipo_atencion)"
                    >
                      <div class="row">
                        <div class="col-sm-5">
                          <h4><strong>N° {{pendiente.numero}}</strong></h4>
                        </div>
                        <div class="col-sm-7 text-right">
                          <h4><strong>Hora: {{pendiente.hora}}</strong></h4>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div>
                        <h5 class="text-success"><strong>{{pendiente.paciente}}</strong></h5>
                      </div>
                      <div>
                        <h5 class="mb-0"><b>{{pendiente.medico}}</b></h5>
                      </div>
                      <div>{{pendiente.tipo_atencion}}</div>
                      <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8 text-right" style="font-size: 9px;">
                          <strong>Origen: </strong>{{pendiente.origen}}
                          <br>
                          <strong>Fecha: </strong>{{pendiente.fecha}}</div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalEnfermeria
      v-model="showEnfermeria"
      :pendiente="pendiente"
      :idEnfermeria = "idEnfermeria"
      @registroActualizado="listarRegistros"
    />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import { FUNCIONALIDADES, TIPO_ATENCION } from '@/utils/globalConventions';
import modalEnfermeria from '../components/theModalEnfermeria.vue';

export default {
  name: 'enfermeriaIndex',
  components: {
    modalEnfermeria,
  },
  data() {
    return {
      estado: true,
      cierre: false,
      pagina_actual: 1,
      por_pagina: 50,
      pagina_opciones: [50, 100],
      isLoading: false,
      pendientes: [],
      showEnfermeria: false,
      pendiente: null,
      enfermeria: null,
      consulta: null,
      medico: null,
      signos_vitales: null,
      tableroInformacion: null,
      fc: null,
      fr: null,
      diastolica: null,
      sistolica: null,
      temperatura: null,
      idEnfermeria: 0,
    };
  },
  created() {
    this.listarRegistros();
  },
  methods: {
    async onClickHeaderCard(item) {
      this.idEnfermeria = item.id;
      this.showEnfermeria = true;
      await this.cargarDatos(item);
    },
    async cargarDatos(item) {
      try {
        const response = await axios.get(`clinic/consultation/patient/enfermeria/edit/${item.id}`);
        this.enfermeria = response.data.data;
        this.pendiente = this.enfermeria;
        this.idEnfermeria = item.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    onClickEliminarIcon() {
    },
    async listarRegistros() {
      this.isLoading = true;
      try {
        const params = {
          page: this.pagina_actual,
          per_page: this.por_pagina,
          cierre: this.cierre,
        };
        const response = await axios.get('clinic/consultation/patient/enfermeria', { params });
        this.pendientes = response.data.data.consultas_enfermeria;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    cambiarColorHead(tipo) {
      if (tipo === TIPO_ATENCION.EMERGENCIA) {
        return 'bg-danger';
      }
      if (tipo === TIPO_ATENCION.MEDICINA_GENERAL) {
        return 'bg-warning';
      }
      return 'bg-gray';
    },
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.ENFERMERIA);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
    ...mapGetters('main', [
      'getFuncionalidades',
    ]),
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(182, 182, 182);
}
.border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
